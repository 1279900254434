<template>
  <div class="card card-bordered">
      <div class="card-inner">
          <a-breadcrumb style="padding-bottom: 10px;">
            <router-link :to="{name: 'reports'}"><a-breadcrumb-item>Back To Reports</a-breadcrumb-item></router-link>
            <a-breadcrumb-item>Tickets By User</a-breadcrumb-item>
          </a-breadcrumb>
          <div class="row">
            <div class="col-3">
                    <a-select show-search allowClear option-filter-prop="children" @change="getReport()" v-model="widgetFetcher.userId" placeholder="Worked On By User" style="width: 100%">
                        <a-select-option v-for="u in users" :key="u.id">
                            {{ u.userName}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-3">
                    <a-select show-search allowClear option-filter-prop="children" @change="getReport()" v-model="widgetFetcher.assignedUserId" placeholder="Assigned To User" style="width: 100%">
                        <a-select-option v-for="u in users" :key="u.id">
                            {{ u.userName}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-6">
                    <div class="float-right">
                        <div class="btn-group" style="margin-right: 15px;">
                            <button @click="setMonth('thisMonth')" type="button" class="btn btn-primary btn-sm">This Month</button>
                            <button @click="setMonth('lastMonth')" class="btn btn-dark btn-sm">Last Month</button>
                            <button @click="setMonth('monthBeforeLast')" class="btn btn-dark btn-sm">Month <em style="font-size:10px;" class="icon ni ni-caret-left-fill"></em> Last</button>
                            <button @click="setMonth('thisYear')" class="btn btn-dark btn-sm">This Year</button>
                        </div>
                        <a-date-picker @change="getReport()" v-model="widgetFetcher.start" format="DD/MM/YYYY" placeholder="Start Date" style="margin-right: 10px;"/>
                        <a-date-picker @change="getReport()" v-model="widgetFetcher.end" format="DD/MM/YYYY" placeholder="End Date"/>
                    </div>
                </div>
            </div>
            <hr class="preview-hr">
            <div class="row">
                <div class="col-6" v-if="ticketCounts">
                    <ticketsbyuserchart :needsRefresh="needsRefresh" :widgetFetcher="widgetFetcher"/>
                </div>
                <div class="col-6" v-if="ticketCounts">
                    <div class="row">
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">Total Tickets Completed</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ ticketCounts.completedCount }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">Total Tickets Outstanding Currently</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ ticketCounts.outstandingCount }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title text-center">
                                                <h5 class="title text-center">Total Time Spent Working On Tickets (
                                                    <a v-if="environmentSettings.showDayReportStats == true">Days</a>
                                                    <a v-if="environmentSettings.showDayReportStats == false">Hours</a>
                                                )</h5>
                                                <a @click="updateEnvironmentSettings('day')">Change To 
                                                    <a v-if="environmentSettings.showDayReportStats == false">Days</a>
                                                    <a v-if="environmentSettings.showDayReportStats == true">Hours</a>
                                                </a>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center" v-if="environmentSettings.showDayReportStats == false">{{ ticketCounts.timeSpentWorking}}</h4>
                                                    <h4 class="text-center" v-if="environmentSettings.showDayReportStats == true">{{ Math.round((ticketCounts.timeSpentWorking / 24) * 10 ) / 10 }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title text-center">
                                                <h5 class="title text-center">Average Time Spent Working On Tickets (
                                                    <a v-if="environmentSettings.showDayReportStats == true">Hours</a>
                                                    <a v-if="environmentSettings.showDayReportStats == false">Mins</a>
                                                )</h5>
                                                <a @click="updateEnvironmentSettings('hour')">Change To 
                                                    <a v-if="environmentSettings.showDayReportStats == false">Mins</a>
                                                    <a v-if="environmentSettings.showDayReportStats == true">Hours</a>
                                                </a>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center" v-if="environmentSettings.showHourReportStats == false">{{ ticketCounts.avgTimeSpentPerTicket }}</h4>
                                                    <h4 class="text-center" v-if="environmentSettings.showHourReportStats == true">{{ Math.round((ticketCounts.avgTimeSpentPerTicket / 60) * 10 ) / 10 }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">Total Tickets Missed SLA This Month</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ ticketCounts.missedSLACount }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="preview-hr">
            <div class="col-12" v-if="tickets">
                <vue-good-table v-if="tickets"
                    :columns="columns"
                    :rows="tickets"
                    :row-style-class="rowStyleClass"
                    styleClass="vgt-table condensed tblhov"
                    :search-options="{ enabled: true, placeholder: 'Search Tickets'}"
                    :sort-options="{ enabled: true,}"
                    @on-cell-click="showTicket"
                >
                <template slot="table-row" slot-scope="props">
                    <span :class="props.row.slaDueDate < props.row.completedDate ? 'red' : 'green' " v-if="props.column.field == 'title'">{{ props.row.title }}</span>
                    <span :class="props.row.slaDueDate < props.row.completedDate ? 'red' : 'green' " v-else-if="props.column.field == 'accountName'">{{ props.row.accountName }}</span>
                    <span :class="props.row.slaDueDate < props.row.completedDate ? 'red' : 'green' " v-else-if="props.column.field == 'priority'">{{ props.row.priority }}</span>
                    <span :class="props.row.slaDueDate < props.row.completedDate ? 'red' : 'green' " v-else-if="props.column.field == 'status'">{{ props.row.status }}</span>
                    <span :class="props.row.slaDueDate < props.row.completedDate ? 'red' : 'green' " v-else-if="props.column.field == 'createdDate'">{{ $moment.utc(props.row.createdDate).local().format("H:mm DD/MM/YY") }}</span>
                    <span :class="props.row.slaDueDate < props.row.completedDate ? 'red' : 'green' " v-else-if="props.column.field == 'slaDueDate'">{{ $moment.utc(props.row.slaDueDate).local().format("H:mm DD/MM/YY") }}</span>
                    <span :class="props.row.slaDueDate < props.row.completedDate ? 'red' : 'green' " v-else-if="props.column.field == 'completedDate'">{{ $moment.utc(props.row.completedDate).local().format("H:mm DD/MM/YY") }}</span>
                </template>
                </vue-good-table>
            </div>
      </div>
    <a-drawer :width="600"
      placement="right"
      :closable="false"
      @close="closeTicket()"
      :visible="showTicketData">
      <ticket :ticket="selectedTicket" />
    </a-drawer>
  </div>
</template>

<script>
import ticket from '@/components/desk/tickets/ticket.vue'
import ticketsbyuserchart from '@/components/widgets/desk/reports/ticketsbyuserchart.vue'

export default {
    components: { ticket, ticketsbyuserchart },
    data() {
        return {
            environmentSettings: {},
            needsRefresh: null,
            users: [],
            widgetFetcher: { 
                start: this.$moment.utc().startOf('month'),
                end: this.$moment.utc().endOf('month'),
            },
            selectedMonth: 'thisMonth',
            tickets: [],
            ticketCounts: null,
            showTicketData: false,
            selectedTicket: {},
            columns: [{
              label: 'Title',
              align: 'start',
              sortable: false,
              field: 'title',
            }, {
              label: 'Account Name',
              field: 'accountName'
            }, {
              label: 'Priority',
              field: 'priority'
            }, {
              label: 'Status',
              field: 'status'
            }, {
              label: 'Created Date',
              field: 'createdDate'
            }, {
              label: 'SLA Due Date',
              field: 'slaDueDate'
            }, {
              label: 'Completed Date',
              field: 'completedDate'
            }],
        }
    },
    created() {
        this.getView()
        this.getEnvironmentSettings()
        this.getLists()
    },
    methods: {
        getView (){ this.$http.get('/ViewAccess/ticketByUser')
        .then(() => { 
        })
        .catch(() => { 
        })},
        updateEnvironmentSettings(e) {
            if (e == 'hour') {
                if (this.environmentSettings.showHourReportStats == true) {
                    this.environmentSettings.showHourReportStats = false
                } else {
                    this.environmentSettings.showHourReportStats = true
                }
            }
            if (e == 'day') {
                if (this.environmentSettings.showDayReportStats == true) {
                    this.environmentSettings.showDayReportStats = false
                } else {
                    this.environmentSettings.showDayReportStats = true
                }
            }
            this.$http.post('/settings/Update_UserEnvironment_Settings/', this.environmentSettings)
            .then(() => {
            //no response needed
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getEnvironmentSettings() {
            this.$http.get('/settings/Get_UserEnvironment_Settings/')
            .then((response) => {
                this.environmentSettings = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        showTicket(params) {
          this.selectedTicket = params.row
          this.showTicketData = true
        },
        rowStyleClass(props) {
            if (props.slaDueDate > props.completedDate) {
                return 'red'
            }
        },
        getLists(){
          this.$http.get('/lists/Get_Users')
          .then((response) => {
              this.users = response.data
          })
          .catch(() => {
          this.$message.error('There has been an error')
          })
          
        },
        getReport(){
          this.$http.post('/reports/Get_TicketsByUser/', this.widgetFetcher)
          .then((response) => {
              this.tickets = response.data
          })
          .catch(() => {
            this.$message.error('There has been an error')
          })
          this.$http.post('/reports/Get_TicketsByUserCounts/', this.widgetFetcher)
          .then((response) => {
              this.ticketCounts = response.data
          })
          .catch(() => {
            this.$message.error('There has been an error')
          })
          this.initiateRefresh()
        },
        closeTicket(){
          this.selectedTicket = null
          this.showTicketData = false
        },
        setMonth(s){
            if (s === 'thisMonth') {
                this.widgetFetcher.start = this.$moment.utc().startOf('month'),
                this.widgetFetcher.end = this.$moment.utc().endOf('month')
            } else if (s === 'lastMonth') {
                this.widgetFetcher.start = this.$moment.utc().subtract(1, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment.utc().subtract(1, 'months').endOf('month')
            } else if (s === 'monthBeforeLast') {
                this.widgetFetcher.start = this.$moment.utc().subtract(2, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment.utc().subtract(2, 'months').endOf('month')
            } else if (s === 'thisYear') {
                this.widgetFetcher.start = this.$moment.utc().startOf('year')
            }
            this.getReport()
        },
        initiateRefresh(){
            if (this.needsRefresh === true) {
                this.needsRefresh = false
            } else {
                this.needsRefresh = true
            }
        }
    }
}
</script>

<style scoped>
  .stat{
    height: 165px
  }
  .red{
      color: red;
  }
  .green {
      color: green;
  }
</style>